.card{
    margin-top: 50px;
    width: 20rem;
    /* height: 20rem; */
    /* From https://css.glass */
background: rgba(201, 233, 250, 0.81);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.1px);
-webkit-backdrop-filter: blur(5.1px);
border: 1px solid rgba(201, 233, 250, 0.84);
  }
  .card_box{
    
     
      
      transition: transform 1s ease-in-out; 
   display: flex;
   justify-content: center;
   align-items: center;
      transition-delay: 0.5s;
      
    
  }
    .card_box:hover {
      transform: scale(1.1); 
    }
  