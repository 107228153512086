@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Open+Sans:wght@400;600;700;800&family=PT+Serif:wght@700&display=swap');
.footer_container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
position: fixed;
left: 0;
bottom: 0;

    margin-top: .6rem;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
background: rgba(255, 255, 255, 0.73);

/* box-shadow:  30px 4px 0 rgba(0, 0, 0, 0.1); */
backdrop-filter: blur(5.4px);
-webkit-backdrop-filter: blur(5.4px);
border: 1px solid rgba(255, 255, 255, 0.84);
font-size: small;
}