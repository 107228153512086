
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
* {
  box-sizing: border-box;
}

html {
  /* font-family: sans-serif;  */
  /* font-family: 'Solitreo', cursive; */
  font-family: 'Open Sans', sans-serif;
 /* background: #fffffb
  */
  background: rgba(199, 235, 246, 0.31);
}

body {
  margin: 0;
}

main {
  margin-top: 6rem;
}