.main-header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(14,81,116);
background: linear-gradient(106deg, rgba(14,81,116,1) 3%, rgba(44,148,205,1) 49%, rgba(44,148,205,1) 70%, rgba(44,148,205,1) 92%);
  padding: 0 2rem;
  z-index: 99999999;
}

.message_button{
  color: white;
  background: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: 'Courier New', Courier, monospace;
}
.logoContainer{
  display: flex;
  gap: 25px;
}
.logo{
  width: 5rem;
  height: 3rem;
}

.main-header h1 {
  color: white;
}

@media (max-width: 474px){
  .main-header h1{
    font-size: 1.3rem;
  }
}
  
