.admin_message_container{
    display: flex;
    /* From https://css.glass */
    height: auto;

}

.admin_message_box_container{
    /* From https://css.glass */

width: 80%;
margin-top: 3rem;
margin-left: 5rem;
height: 40rem;

/* position: fixed; */
}
.admin_message_heading{
    /* padding: 1rem; */
    margin-left: 3rem;
    width: 30px;
}
.admin_message_box{
    margin: 2rem 3rem 3rem 0;
    width: fit-content;
}
.pagination{
    margin-right: 2rem;
  }
.message_table{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.message_table_item1{
    width: 90rem;
}
.t1{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.22);
/* border-radius: 16px; */
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
/* backdrop-filter: blur(5.2px); */
/* -webkit-backdrop-filter: blur(5.2px); */
/* border: 1px solid rgba(255, 255, 255, 0.87); */
}