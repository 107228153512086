
.adminCenter{
    display: flex;
    /* overflow: hidden; */
    /* flex: 1 0 auto;  */
}
 
.containerHome{
    padding: 1rem;
        margin: 5rem;
        width: 80%;
        height: 75vh;
  
      
  }
  
  
  .cardContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  
  }

  .datePicker{
    width: 30rem;
    display: flex;
    justify-content: space-around;
    
  }

  .date{
    
  }
  