@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=PT+Serif:wght@700&display=swap');

* {
  margin: 0;
  padding: 0px;
}

.adminhome_container {
  display: flex;
}

.dasboard_container {
  display: flex;
  width: 100%;
  height: 85vh;
  /* align-items: center; */
justify-content: center;
  margin-top: 5rem;
  margin-left: 1rem;
  margin-right: 1rem;

 /* From https://css.glass */
/* From https://css.glass */
background: rgba(255, 255, 255, 0.19);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(1px);
-webkit-backdrop-filter: blur(1px);
border: 1px solid rgba(255, 255, 255, 0.84);
}


.allcenter{
/* margin-left: -14rem; */
/* margin-right: 2rem; */
}

.doughnut{
  display: flex;
/* align-items: center; */
/* justify-content: space-between; */
margin-top: 2rem;
width: 22rem;
height: 22rem;

}

.cardT{
  display: flex;
  flex-direction: column;
  /* margin-left: -10rem; */
  /* align-items: center; */
  width: 40rem;/* From https://css.glass */
  background: rgba(158, 205, 237, 0.5);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(11.6px);
  -webkit-backdrop-filter: blur(11.6px);
  border: 1px solid rgba(255, 255, 255, 0.87);
 
  margin-top: 20px;
  margin-left: 1%;
  margin-right: 20%;

  
}
.cardT_heading{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardStudentCount{
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCont{
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 3rem;
  justify-content: space-around;
}
.cont{
  display: flex;
}

.dough1{
  margin-top: 1.8rem;
  /* align-self: fle; */
  
  height:17rem;
  width:17rem
}
.dough2{
  /* margin-top: 10rem; */
  /* margin-right: 1px; */
  margin-left: -20px;
  margin-right: 50px;
  height:28rem;
  width:28rem
}