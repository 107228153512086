.form{
  width: 60%;
  max-width: 80rem;
  margin: 7rem auto;
  padding: 2rem;
  
}
.studentdataform {
    width: 80rem;
    max-width: 50rem;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
    
    text-align: center;
  }
  .form_control {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem;
    gap: 1rem;
    margin-bottom: 2rem;
    margin: 2rem auto;
    margin-right: 2rem;
    text-align: left;
    align-items: center;
  }
  
  .control {
    margin: 1rem 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  
  .control label,
  .control input {
    display: block;
  }
  
  .control label {
    font-weight: bold;
    flex: 1;
    color: #464646;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    flex: 3;
    font: inherit;
    padding: 0.35rem 0.35rem;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .control input:focus {
    outline: none;
    border-color: #00115f;
    background: #f6dbfc;
  }
  
  .control.invalid input {
    border-color: red;
    background: #fbdada;
  }
  
  .actions {
    text-align: center;
    text-align: center;
    display: flex;
    flex-direction: row-reverse;
    place-content: space-evenly;
    margin-top: 2.5rem;
    
  }

  .button{
    font: inherit;
  border: 1px solid #00155f;
  background: #000a5f;
  color: white;
  padding: 0.70rem 3rem;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
  }
  .button:hover,
.button:active {
  background: #112988;
  border-color: #112b88;
}
.button:focus {
  outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}
  
  @media (min-width: 768px) {
    .control {
      align-items: center;
      flex-direction: row;
    }
  }

  @media screen and (max-width :474px){
    .button{
      font-size: 1.2rem;
      padding: 0.1rem 2rem;
    }
  }