.home{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  
}




@media (min-width: 768px) {
  .control {
    align-items: center;
    flex-direction: row;
  }
}
.home {
  width: 90%;
  max-width: 90rem;
  padding: 1.5rem;
  margin: 1rem auto;

  /* margin-top: 0; */
  /* text-align: center; */
}
.form_control {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  text-align: left;
  align-items: center;
}
.control {
  /* margin: 1rem 0;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: stretch;
  /* flex-direction: column; */
}

.control label,
.control input {
  display: block;
}

.control label {
  font-weight: bold;
  flex: 1;
  color: #464646;
  margin-bottom: 0.5rem;
}

.control input {
  /* flex: 3; */
  font: inherit;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
}

.control input:focus {
  outline: none;
  border-color: #4f005f;
  background: #f6dbfc;
}

.control.invalid input {
  border-color: red;
  background: #fbdada;
}

.actions {
  text-align: right;
  margin-top: 0.8rem;
  padding: 0.6rem 1.2rem;
  border-radius: 1.5rem;
  margin-left: -.6rem;
}


