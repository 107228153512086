.adminadduser{
    display: flex;
}
.container{
    display: flex;
}
.userListContainer{
    display: flex;
    /* width: -20px; */
    flex: .5;
    justify-content: center;
    height: fit-content;
    margin-top: 10rem;
    width: fit-content;
    margin-left: 17rem;
    
    }
.userListButton{
    text-decoration: none;
    color: black;
}
.adduserContainer{
    margin-top: 5rem;
    flex:4
}