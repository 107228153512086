.card {
 /* From https://css.glass */
/* From https://css.glass */
background: rgba(255, 255, 255, 0.11);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(10.9px);
-webkit-backdrop-filter: blur(10.9px);
border: 1px solid rgba(255, 255, 255, 0.37);
}
