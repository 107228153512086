
.login {
    width: 90%;
    max-width: 40rem;
    margin: 7rem auto;
    padding: 1rem;
    
  }
  .logintext{
    text-align: center;
  }
  
  .control {
    margin: 1rem 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
  
  .control label,
  .control input {
    display: block;
  }
  
  .control label {
    font-weight: bold;
    flex: 1;
    color: #464646;
    margin-bottom: 0.5rem;
  }
  
  .control input {
    flex: 3;
    font: inherit;
    padding: 0.35rem 0.35rem;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .control input:focus {
    outline: none;
    border-color: #4f005f;
    background: #f6dbfc;
  }
  
  .control.invalid input {
    border-color: red;
    background: #fbdada;
  }
  
  .actions {
    text-align: center;
  }
  
  .loginImageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* flex-wrap: wrap; */
  }
  .loginImage{
    width: 25rem;
    height: 23rem;
  }
  
  @media (min-width: 768px) {
    .control {
      align-items: center;
      flex-direction: row;
    }
  
  }