@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');


.navbar-menu {
    margin-top: 4rem;
    /* position: fixed; */
  height:87vh;
  
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 0 16px 16px 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
  border: 1px solid rgba(255, 255, 255, 0.79);
  transition: all 0.5s ease;
  /* width:20px */
  
}
.burger{
  display:flex;
  justify-content: flex-end;
}
.burger img {
  padding: 25px 8px 0px 0px;
  cursor: pointer;
}
.navbar__list {
  list-style-type: none;
  margin-top: -25px;
  
}
.navbar__li {
  display: inline-block;
  padding:0px 0px 0px 12px;
  position: relative;
  top:-6px;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 38px;
  color: #000000;
  font-family: "Lato";
  font-weight: 400;
}
  .navbar__li-img{
    width: 57px;
    height: 28px;
   
  }
  .navbar__li-user{
    height:31px;
    width: 57px;
  }
  .navbar__li-dashboard{
    width:58px;
    height: 30px;
    margin-left: -5px;
    /* margin-right: 1rem;   */
  }
  .navbar__li-location{
    height:32px;
    width:59px;
  }
  .navbar__li-process{
    margin-left: 0.6rem;
    height: 26px;
    width: 36px;
  }
.navbar__li-box:first-child {
  margin-top:53px;
  
}
.navbar__li-box {
  height: 43px;
  margin-top:10px;
}
.navbar__li-box:hover {
  border-left: 7px solid #010101;
  cursor: pointer;
}
