.button {
  font: inherit;
  background: rgba(255, 255, 255, 0.61);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.2px);
  -webkit-backdrop-filter: blur(3.2px);
  border: 1px solid rgba(255, 255, 255, 0.54);
  padding: 0.70rem 3rem;
  cursor: pointer;
  font-size: 1.15rem;
  border-radius: 30px;
}

.button:hover,
.button:active {
  background: #6692dd;
  border-color: #ffffff;
  color: white;
}

.button:focus {
  outline: none;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}
