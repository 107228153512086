.top {
  width: 150vh;
}
.addStudent {
  margin-top: 3rem;
  margin-bottom: -2rem;
  height: 6rem;
  background: rgb(14, 81, 116);
  box-shadow: 0 4px 30px rgba(234, 235, 240, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.studentdataform {
  width: 90%;
  max-width: 90rem;
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  text-align: center;
}
.paper {
  width: 100%;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: -4rem;
}
.studentTopBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formcontrol {
  width: 3rem;
}
.addButton {
  align-items: center;
  justify-content: center;
  margin-left: 3rem;
  margin-top: 1rem;
}
.downloadIcon {
  text-decoration: none;
  margin-top: 1rem;
  margin-bottom: 0;
  width: 10rem;
  align-items: center;
  margin-right: 2rem;
}
.pagination {
  margin-right: 2rem;
}
.control {
  margin: 1rem 0;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.control label,
.control input {
  display: block;
}

.control label {
  font-weight: bold;
  flex: 1;
  color: #464646;
  margin-bottom: 0.5rem;
}

.control input {
  flex: 3;
  font: inherit;
  padding: 0.35rem 0.35rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.control input:focus {
  outline: none;
  border-color: #4f005f;
  background: #f6dbfc;
}

.control.invalid input {
  border-color: red;
  background: #fbdada;
}

.actions {
  text-align: center;
}

@media (min-width: 768px) {
  .control {
    align-items: center;
    flex-direction: row;
  }
}
