.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav li {
  margin: 0;
  margin-left: 2rem;
}

.nav a {
  text-decoration: none;
  color: white;
}

.nav a:hover,
.nav a:active {
  color: #cadefb;
}

.nav button {
  font: inherit;
  background:linear-gradient(231deg, rgb(179, 217, 237) 0%, rgb(189, 218, 234) 29%, rgb(202, 229, 239) 91%);
  border: 1px solid #96e9ee;
  padding: 0.5rem 1.5rem;
  color: rgb(15, 10, 10);
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(160, 212, 243, 0.26);
  border-radius: 20px;
}

.nav button:focus {
  outline: none;
}

.nav button:hover,
.nav button:active {
  color: #080f1f;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
}
