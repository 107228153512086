.compose_container{
    width: 60rem;
    height: 100rem;
    display: flex;
    margin: 10% 30% 0 30%;
    
}

.compose_form{
    display: flex;
    flex-direction: column;

}

.compose_form_item{
    margin-bottom: 3rem;
    width: 30rem;
}

.compose_form_action{
    display: flex;
    justify-content: space-around
}
